import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './edit-user-type-points.scss';

const EditUserTypePoints = ({ 
  initialPoints = [
    { key: 'Judges', value: 1000 },
    { key: 'Jurors', value: 500 },
    { key: 'Mentors', value: 300 },
    { key: 'Competitors', value: 100 },
    { key: 'Guests', value: 10 },
  ], 
  handlePointsChange 
}) => {
  const [points, setPoints] = useState(initialPoints);

  useEffect(() => {
    handlePointsChange(points);
  }, [points, handlePointsChange]);

  const handleValueChange = (index, event) => {
    const newPoints = points.map((point, i) => {
      if (i === index) {
        return { ...point, value: parseInt(event.target.value) || 0 };
      }
      return point;
    });
    setPoints(newPoints);
  };

  return (
    <div className="points-container">
      {points.map((point, index) => (
        <div key={index} className="point">
          <span className="key-name">{point.key}</span>
          <input
            type="number"
            name="value"
            value={point.value}
            onChange={(e) => handleValueChange(index, e)}
            className="value-input"
          />
        </div>
      ))}
    </div>
  );
};

EditUserTypePoints.propTypes = {
  initialPoints: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number
    })
  ),
  handlePointsChange: PropTypes.func.isRequired
};

export default EditUserTypePoints;
