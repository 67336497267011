import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/generic/button';
import './sponsor-configuration.scss';

import { BURNETT_API_ENDPOINT } from '../../../config';

const SponsorConfiguration = ({ sponsorList, updateCompetitionSponsors, competitionId }) => {
  const { user, getIdTokenClaims } = useAuth0();
  const [sponsors, setSponsors] = useState(sponsorList);
  const [uploadProgress, setUploadProgress] = useState({});
  const fileInputRefs = useRef([]); // Track refs for each file input

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSponsors = [...sponsors];
    updatedSponsors[index][name] = value;
    setSponsors(updatedSponsors);
    updateCompetitionSponsors(updatedSponsors);
  };

  const uploadImage = async (index, file) => {
    const uniqueId = uuidv4();

    const newSponsorLogoParams = {
      competition_id: competitionId,
      sponsor_name: sponsors[index].name || uniqueId,
      sponsor_id: uniqueId,
      file_type: file.type,
    };

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const response = await axios.post(`${BURNETT_API_ENDPOINT}/update-competition-sponsor-logo`, newSponsorLogoParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { presigned_url: preSignedUrl } = response.data;

      await axios.put(preSignedUrl, file, {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress((prev) => ({ ...prev, [index]: percentCompleted }));
        }
      });

      // Delay to allow backend to finish processing the image
      await new Promise(resolve => setTimeout(resolve, 3000));

      const updatedLogoUrl = `https://longjump-public-assets.s3.us-east-1.amazonaws.com/compressed-${competitionId}-${uniqueId}.jpg`;

      // Update sponsor with new logo URL after processing delay
      const updatedSponsors = [...sponsors];
      updatedSponsors[index].logo_url = updatedLogoUrl;
      setSponsors(updatedSponsors);
      updateCompetitionSponsors(updatedSponsors);

      setUploadProgress((prev) => ({ ...prev, [index]: null })); // Reset upload progress

    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(index, file);
    }
  };

  const handleAddLogoClick = (index) => {
    fileInputRefs.current[index].click(); // Trigger file input click for the specified index
  };

  const handleDeleteSponsor = (index) => {
    const updatedSponsors = sponsors.filter((_, i) => i !== index);
    setSponsors(updatedSponsors);
    updateCompetitionSponsors(updatedSponsors);
  };

  const handleAddSponsor = () => {
    const newSponsor = { logo_url: '', web_url: '', name: '' };
    const updatedSponsors = [...sponsors, newSponsor];
    setSponsors(updatedSponsors);
  };

  return (
    <div className="sponsor-configuration-container">
      {sponsors.map((sponsor, index) => (
        <div key={index} className="sponsor-item">
          <div className="sponsor-logo">
            {sponsor.logo_url && <img src={sponsor.logo_url} alt={`${sponsor.name || 'Sponsor'} logo`} />}
            <input
              type="file"
              accept="image/jpeg, image/png"
              style={{ display: 'none' }}
              ref={(el) => (fileInputRefs.current[index] = el)} // Assign ref for each file input
              onChange={(event) => handleImageChange(index, event)}
            />
            {uploadProgress[index] && (
              <div className="upload-progress">Uploading: {uploadProgress[index]}%</div>
            )}
          </div>
          <div className="sponsor-details">
            <input
              type="text"
              name="name"
              value={sponsor.name}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Sponsor Name"
            />
            <input
              type="text"
              name="web_url"
              value={sponsor.web_url}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Sponsor Website URL"
            />
          </div>
          <div className="sponsor-actions">
            <Button
              text="Add Logo"
              onClick={() => handleAddLogoClick(index)}
              className="primary add-logo-button"
            />
            <div className="sponsor-action delete" onClick={() => handleDeleteSponsor(index)}>
              Delete
            </div>
          </div>
        </div>
      ))}
      <div
        className={`sponsor-action add`}
        onClick={handleAddSponsor}
      >
        + add sponsor
      </div>
    </div>
  );
};

export default SponsorConfiguration;
