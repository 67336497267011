import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import MuxPlayer from '@mux/mux-player-react';
import axios from 'axios';
import { toast } from "react-toastify";
import Button from '../button';
import Popup from '../popup';
import { BURNETT_API_ENDPOINT } from '../../../config';
import './upload-submission-files.scss';

const UploadComponent = ({ competitionId, submissionId, existingVideoUrl, fetchSubmissionData, updateUploadProgress, muxPlaybackId }) => {
  const { user, getIdTokenClaims } = useAuth0();
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress]= useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading]= useState(false);

  const handleConfirmUploadClick = async () => {
    if (isLoading || !competitionId || !submissionId || !videoFile) return;
  
    const newVideoParams = {
      competition_id: competitionId,
      submission_id: submissionId,
      video_name: videoFile.name,
      video_type: videoFile.type,
      video_owner: user.email
    }

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const replaceVideoResult = await axios.post(`${BURNETT_API_ENDPOINT}/update-competition-submission-video`, newVideoParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { data } = replaceVideoResult;

      const { presigned_url: preSignedUrl } = data;

      await axios.put(preSignedUrl, videoFile, {
        headers: {
          "Content-Type": videoFile.type
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress(percentCompleted);
        }
      });

      setVideoFile(null);
      setUploadProgress(null);
      // toast.success('Submission video replaced successfully');
      setIsLoading(false);
      fetchSubmissionData();

    } catch (error) {
      console.error(error);
      toast.error('Failed to replace submission video');
      setUploadProgress(null);
      setIsLoading(false);
    }
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      setVideoFile(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (!videoFile || existingVideoUrl) return;
    handleConfirmUploadClick();
  }, [videoFile])

  const handleReplaceVideoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    updateUploadProgress(uploadProgress)
  }, [uploadProgress])

  const handleCancelVideoReplace = () => {
    if (uploadProgress) return;
    setVideoFile(null);
    setVideoFile(null);
  }

  return (
    <div className="upload-submission-files-container">
      <div className="upload-section">
        <h2>Submission video</h2>
        <p>Your video is required</p>

        {existingVideoUrl && videoFile ? (
          <div style={uploadProgress ? {pointerEvents: "none"} : {}}>
            <Popup isOpen={videoFile} onClose={handleCancelVideoReplace}>
              <div className="confirm-replace-video-popup">
              {uploadProgress ? (
                <div className="upload-progress">
                  <span>Uploading {videoFile.name}:</span>
                  <b>{uploadProgress}%</b>
                </div>
              ) : (
                <>
                  <h2>Replace Submission Video</h2>
                  <h3>Are you sure you want to replace the existing submission video with this new video?</h3>
                  <b>{videoFile.name}</b>
                  <Button text={!uploadProgress ? 'Confirm & Upload' : 'Loading'} onClick={()=> handleConfirmUploadClick()} className="primary" />
                </>
              )}
              </div>
            </Popup>
          </div>
        ) : <></>}

        {existingVideoUrl ? (
          <div>
              {muxPlaybackId ? (
                <MuxPlayer
                  playbackId={muxPlaybackId}
                  streamType="on-demand"
                  accentColor="#0090F2"
                />
              ) : <video width="100%" height="100%" controls>
              <source src={existingVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}
            <input
              type="file"
              className="file-input"
              accept="video/mp4"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button text='Replace Video' onClick={()=> handleReplaceVideoClick()} className="primary replace-video-button" />
          </div>
        ) : (
          <div
            className="popup-video-drop-zone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleDropZoneClick}
          >
            {!videoFile && <p>Drag and drop a video file here, or click to select one</p>}
            <input
              type="file"
              className="file-input"
              accept="video/mp4"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            {videoFile && !uploadProgress && <p>Selected video: {videoFile.name}</p>}
            {uploadProgress ? (
              <div className="upload-progress">
                <span>Uploading {videoFile.name}:</span>
                <b>{uploadProgress}%</b>
              </div>) : (<></>)}
          </div>
        )}
        
      </div>
      
      {/* <div className="upload-files-container">
        
      </div> */}
    </div>
  );
};

export default UploadComponent;
