import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import moment from 'moment';
import { BURNETT_API_ENDPOINT } from '../../config';
import Button from '../../components/generic/button';
import BackButton from '../../components/generic/backButton';
import './profile.scss';

function Profile() {
  const navigate = useNavigate();
  const { user, getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [customPictureUrl, setCustomPictureUrl] = useState("");
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [error, setError] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [userId, setUserId] = useState("");
  const [uploadProgress, setUploadProgress]= useState(null);


  const getUserProfileSettings = async (onlyReplaceImage) => {
    try {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-profile`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;

      const {
        custom_first_name = "",
        custom_last_name = "",
        custom_bio = "",
        custom_location = "",
        custom_picture = "",
        user_id = "",
        email
      } = data;

      if (!onlyReplaceImage) {
        setFirstName(custom_first_name);
        setLastName(custom_last_name);
        setBio(custom_bio);
        setLocation(custom_location);
        setEmail(email);
        setUserId(user_id)
      }

      setCustomPictureUrl(custom_picture);
      setIsLoading(false);

    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!user) return;
    getUserProfileSettings();
  }, [user]);

  const handleFirstNameInputChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameInputChange = (e) => {
    setLastName(e.target.value);
  };

  const handleBioInputChange = (e) => {
    setBio(e.target.value);
  };

  const handleLocationInputChange = (e) => {
    setLocation(e.target.value);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const maxFileSize = 1000 * 1024;
  
    if (file) {
      if (file.size > maxFileSize) {
        toast.error('File size should be less than 1mb.');
        return;
      } 
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        setIsImageUploading(true);
        try {
          const claims = await getIdTokenClaims();
          const idToken = claims.__raw;
  
          // Request presigned URL from the backend
          const response = await axios.post(
            `${BURNETT_API_ENDPOINT}/update-user-profile-picture`,
            { file_name: file.name, file_type: file.type },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json"
              }
            }
          );
  
          const { presigned_url, key } = response.data;
  
          // Upload image to S3
          await axios.put(presigned_url, file, {
            headers: {
              "Content-Type": file.type
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              setUploadProgress(percentCompleted);
            }
          });
  
          setTimeout(() => {
            setProfileImageFile();
            getUserProfileSettings(true);
            toast.success('Profile image uploaded successfully.');
            setUploadProgress(null);
          }, 3000);
        } catch (error) {
          console.error(error);
          toast.error('Failed to upload profile image.');
          setUploadProgress(null);
        } finally {
          setIsImageUploading(false);
        }
      } else {
        toast.error('Please upload a JPG or PNG file.');
      }
    }
  };

  const handleViewProfile = () => {
    navigate(`/profile/${userId}`);
  }
  
  const handleSave = async () => {
    if (isSaveLoading) return;

    const params = {
      custom_first_name: firstName,
      custom_last_name: lastName,
      custom_bio: bio,
      custom_location: location,
    };

    try {
      setIsSaveLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const updateResult = await axios.post(`${BURNETT_API_ENDPOINT}/update-profile`, params, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      if (profileImageFile) {
        const formData = new FormData();
        formData.append('profileImage', profileImageFile);

        await axios.post(`${BURNETT_API_ENDPOINT}/upload-profile-image`, formData, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      setIsSaveLoading(false);
      toast.success('Profile saved successfully.');
      window.location.reload();

    } catch (error) {
      console.error(error);
      toast.error('Failed to save profile.');
      setIsSaveLoading(false);
    }
  }

  return (
    <div className="profile-container-form">
      <div className="profile-container-content">
        <h1>Profile settings</h1>
        <div
          className={`upload-profile-picture ${isImageUploading ? "image-processing" : ""}`}
          style={{ backgroundImage: `url(${customPictureUrl})`}}
          onClick={() => document.getElementById('profile-image-input').click()}
        >
          <input
            type="file"
            id="profile-image-input"
            accept="image/jpeg, image/png"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <div className="add-cta">{!uploadProgress ? "+ Add" : `${uploadProgress}%`}</div>
        </div>
        <form>
          <label className="disabled" htmlFor="user-email">Email:</label>
          <input
            type="text"
            className="disabled"
            id="user-email"
            defaultValue={email}
            autoComplete="off"
            spellCheck={false}
          />
          <label htmlFor="user-first-name">First Name:</label>
            <input
              type="text"
              id="user-first-name"
              value={firstName}
              onChange={handleFirstNameInputChange}
              autoComplete="off"
              spellCheck={false}
            />
          <label htmlFor="user-last-name">Last Name:</label>
          <input
            type="text"
            id="user-last-name"
            value={lastName}
            onChange={handleLastNameInputChange}
            autoComplete="off"
            spellCheck={false}
          />
          <label>Bio:</label>
          <textarea
            value={bio}
            onChange={handleBioInputChange}
          />
          <label htmlFor="user-location">Location:</label>
            <input
              type="text"
              id="user-location"
              value={location}
              onChange={handleLocationInputChange}
              autoComplete="off"
              spellCheck={false}
            />
          {/* <Button text="View Profile" onClick={handleViewProfile} className="secondary profile-save-button" /> */}
          <Button text={isSaveLoading ? "Loading" : "Save"} onClick={handleSave} className="primary profile-save-button" />
        </form>
      </div>
    </div>
  )
}

export default Profile;
