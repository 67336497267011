import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import moment from 'moment';
import ListSubmissionsFilterSearch from '../../components/competition/listSubmissionsFilterSearch';
import CompetitionStatus from '../../components/competition/competitionStatus';
import { BURNETT_API_ENDPOINT } from '../../config';
import BackButton from '../../components/generic/backButton';
import './compete.scss';

function Compete() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [competitionData, setCompetitionData] = useState([]);
  const [competitionFilters, setCompetitionFilters] = useState([]);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState('');
  const [selectedCompetitionData, setSelectedCompetitionData] = useState(null);
  const [sponsors, setSponsors] = useState(null);

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do, YYYY');
  };

  const didMount = async () => {
    try {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const getCompetitionData = await Axios.get(`${BURNETT_API_ENDPOINT}/get-competitions`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { data: resData } = getCompetitionData;
      const { data } = resData;
      
      setCompetitionData(data || []);
      const filters = (data || []).map(comp => ({
        competition_name: comp.competition_name,
        competition_id: comp.competition_id,
      }));

      // Check for competition_id in query params
      const queryParams = new URLSearchParams(window.location.search);
      const competitionIdFromQuery = queryParams.get('competition_id');

      if (competitionIdFromQuery) {
        setSelectedCompetitionId(competitionIdFromQuery);
        const selectedData = data.find(comp => comp.competition_id === competitionIdFromQuery);
        if (selectedData) {
          setSelectedCompetitionData(selectedData);
        }
      } else if (filters.length > 0) {
        setSelectedCompetitionId(data[0].competition_id);
        setSelectedCompetitionData(data[0]);
      }

      setCompetitionFilters(filters);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    didMount();
  }, []);

  const onClickCompName = () => {
    navigate(`/competition/${selectedCompetitionId}`);
  }

  useEffect(() => {
    if (!selectedCompetitionId) return;

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('competition_id', selectedCompetitionId);
    navigate({ search: queryParams.toString() });
  }, [selectedCompetitionId, navigate]);

  const handleCompetitionChange = (event) => {
    setIsLoading(true);
    const selectedId = event.target.value;
    setSelectedCompetitionId(selectedId);
    const selectedData = competitionData.find(comp => comp.competition_id === selectedId);
    setSelectedCompetitionData(selectedData);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  useEffect(() => {
    setSponsors(null);
    if (!selectedCompetitionData) return;
    const { competition_sponsors: competitionSponsors } = selectedCompetitionData;
    if (!competitionSponsors) return;
    setSponsors(competitionSponsors);
  }, [selectedCompetitionData])
  
  return (
    <div className="compete-container">
      {competitionFilters && competitionFilters.length > 1 ? (
        <>
          <h2>Team Submissions</h2>
          <div className="filters-header">
            <div className="select-filter-container">
              <div className="filter-item">
                <span>Filter Team Submissions by Competition</span>
                <select onChange={handleCompetitionChange} value={selectedCompetitionId}>
                  <option value="" disabled>Select a competition</option>
                  {competitionFilters.map((comp) => (
                    <option key={comp.competition_id} value={comp.competition_id}>
                      {comp.competition_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </>
      ) : <></>}
      {selectedCompetitionData ? (
        <div className="selected-competition-details">
          {selectedCompetitionId ? <BackButton path={`/competition/${selectedCompetitionId}`} /> : <></> }
          <h1 onClick={() => onClickCompName()}>{selectedCompetitionData.competition_name}</h1>
          <span><b>{formatDate(selectedCompetitionData.competition_start_date)} </b>to <b>{formatDate(selectedCompetitionData.competition_end_date)}</b></span>
          <br />
          <CompetitionStatus startDate={selectedCompetitionData.competition_start_date} endDate={selectedCompetitionData.competition_end_date} deadline={selectedCompetitionData.competition_deadline_date} />        
        </div>
      ) : <></>}

      {!isLoading ? <ListSubmissionsFilterSearch competitionData={competitionData} competitionId={selectedCompetitionId} isCompetePage={true} /> : <></>}

      {(sponsors && sponsors.length > 0) ? (
        <>
          <h3>Sponsors</h3>
          <div className="sponsors-container">
          {sponsors.map((sponsor, index) => {
            const { logo_url: logoUrl, web_url: webUrl, name } = sponsor;

            if (!logoUrl) return null;

            return (
              <div key={index} className="sponsor-item">
                {webUrl ? (
                  <a href={webUrl} target="_blank" rel="noopener noreferrer">
                    <img src={logoUrl} alt={`${name || 'Sponsor'} logo`} />
                  </a>
                ) : (
                  <img src={logoUrl} alt={`${name || 'Sponsor'} logo`} />
                )}
              </div>
            );
          })}
        </div>
        </>
      ) : null}
    </div>
  )
}

export default Compete;
